import Parse from "parse";
import {put} from "redux-saga/effects";
import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {
    loadMyQuestions,
    loadMyQuestionsFailed,
    loadMyQuestionsFinished,
    setMyFavoriteQuestions,
    setMyQuestions
} from "../redux/myQuestionsSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {addQuestionFailed, questionAdded} from "../redux/questionAddSlice";
import {loadQuestionsFailed, questionsLoading, setSearchQuestions} from "../redux/questionSearchSlice";

export const loadMyQuestionsSaga = function*() {
    try {
        // @ts-ignore
        let questions = yield Parse.Cloud.run('webapp.getMyQuestions2', {
            offset: 0,
            limit: 500
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setMyQuestions(questions));

        // @ts-ignore
        questions = yield Parse.Cloud.run('webapp.getMyFavoriteQuestions2', {
            offset: 0,
            limit: 500
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setMyFavoriteQuestions(questions));
        yield put(loadMyQuestionsFinished());
    } catch (error:any) {
        yield put(loadMyQuestionsFailed(error.message));
    }
};

export const toggleFavoriteQuestionSaga = function*(action: PayloadAction<string>) {
    // Todo: manually toggle the value in the redux state before executing the cloud call and undo
    // the action if an error occurs
    try {
        yield Parse.Cloud.run('webapp.toggleFavoriteQuestion', {
            id: action.payload
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(loadMyQuestions());
    } catch (error:any) {
        // ignore error
    }
};

export const addQuestionSaga = function*(action: PayloadAction<{questionText: string; tags: string[]}>) {
    try {
        // @ts-ignore
        const questionId = yield Parse.Cloud.run('webapp.addQuestion', {
            text: action.payload.questionText,
            tags: action.payload.tags,
            source: 'https://startsole.org'
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });

        yield put(questionAdded(questionId));
    } catch (error:any) {
        yield put(addQuestionFailed(error.message));
    }
};

export const searchQuestionsSaga = function*(action: PayloadAction<{ keywords: string; tags: string[] }>) {
    yield put(questionsLoading());
    const lngArray = window.navigator.language.split('-');
    let userLng = 'en';
    if (lngArray[0] === 'es') {
        userLng = 'es';
    }

    if (lngArray[0] === 'ja') {
        userLng = 'ja';
    }

    try {
        // @ts-ignore
        const result = yield Parse.Cloud.run('webapp.findQuestionByTagsAndTextForLanguages', {
            tags: action.payload.tags,
            text: action.payload.keywords,
            languages: [userLng]
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });

        const questions: IQuestion[] = result.questions;
        yield put(setSearchQuestions(questions))
    } catch (error:any) {
        yield put(loadQuestionsFailed(error.message));
    }
};
