import React from "react";
import {SoleStep} from "../common/SoleStepper";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {PicturesPart, validatePicturesPart} from "../parts/50_PicturesPart";
import ReflectObjectivesPart, {validateReflectObjectivesPart} from "../parts/51_ReflectObjectivesPart";
import RatingsPart, {validateRatingsPart} from "../parts/52_RatingsPart";
import FinalPart, {validateFinalPart} from "../parts/53_FinalPart";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectPub} from "../../../redux/userSlice";

const useReflectSteps = ():SoleStep[] => {
    const getLabel = useTranslationLabel();
    const pub = useAppSelector(selectPub);
    const hasRingCo = pub?.rings?.find((ring) => ring.rdn === "co") !== undefined;

    const step1:SoleStep = {
        label: (sole) => getLabel(sole.observations && sole.observations.length > 0 ? 'ui.reflect.review_photos' : 'ui.reflect.add_photos'),
        content: (props) => (<>
            <PicturesPart props={props}/>
        </>),
        validate: (props) => validatePicturesPart(props)
    };

    const step2:SoleStep = {
        label: (_sole) => getLabel('ui.reflect_objectives'),
        content: (props) => <ReflectObjectivesPart props={props}/>,
        validate: (props) => validateReflectObjectivesPart(props)
    };

    const step3:SoleStep = {
        label: (_sole) => getLabel('ui.reflect_ratings'),
        content: (props) => <RatingsPart props={props}/>,
        validate: (props) => validateRatingsPart(props)
    };

    const step4:SoleStep = {
        label: (_sole) => getLabel('ui.reflect_request_help'),
        content: (props) => <FinalPart props={props}/>,
        validate: (props) => validateFinalPart(props)
    };

    const simpleReflectSingleStep:SoleStep = {
        label: (_sole) => getLabel('ui.reflect_request_help'),
        content: (props) => (<>
            <ReflectObjectivesPart props={props}/>
            <RatingsPart props={props}/>
            <PicturesPart props={props}/>
            <FinalPart props={props}/>
        </>),
        validate: (props) => validateRatingsPart(props) || validateReflectObjectivesPart(props)
    };

    if (hasRingCo) {
        return [simpleReflectSingleStep];
    } else {
        return [step1, step2, step3, step4];
    }
};

export default useReflectSteps;
