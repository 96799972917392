import React, {useState} from "react";
import {useAppSelector} from "../../redux/reduxHooks";
import {NumberConstraints} from "../../data/constraints/Constraints";
import TextField from "@material-ui/core/TextField";
import {Rating} from "@material-ui/lab";
import {Grid, makeStyles, Slider, Typography} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {selectConstraints} from "../../redux/constraintSlice";

interface NumberProps {
    initialValue?: number;
    isRating?: boolean;
    isSlider?: boolean;
    label: string;
    rdn: string;
    onChange: (value: number | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
    marginBottom: {
        marginBottom: 32
    }
}));

const percentageText = (value: number) => `${value || 0}%`;

const NumberComponent: React.FC<NumberProps> = (props) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const constraints = useAppSelector(selectConstraints);
    const constraint = props.rdn ? constraints.get(props.rdn) : undefined;
    const isPercent = constraint instanceof NumberConstraints && constraint.unit === 'percent';

    const max = isPercent ? 100 : 5;
    const [value, setValue] = useState<number | undefined>(props.initialValue ? isPercent ? props.initialValue * max / 100 : props.initialValue : undefined);
    const precision = 0.5;

    // TODO: get min/max and formatter from DataPoint
    const minValue = 0;
    const maxValue = 100;
    const suffix = "%";

    const marks = [
        {value:minValue, label:minValue + suffix},
        {value:maxValue, label:maxValue + suffix}
    ];

    const valuetext = (value: number) => `${value}${suffix}`;

    if (constraint instanceof NumberConstraints) {
        if (value === undefined && constraint.defaultValue) {
            setValue(constraint.defaultValue);
        }
        const minError = (value && constraint.min) ? value < constraint.min : false;

        if (constraint.unit === 'percent') {
            if (value === undefined) {
                setValue(50);
            }
            return (
                <Grid container>
                    <Grid item xs={12} sm={7}>
                        <Typography component="legend">{props.label}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                            <Slider
                                min={minValue}
                                max={maxValue}
                                marks={marks}
                                getAriaValueText={percentageText}
                                value={value}
                                defaultValue={value}
                                valueLabelFormat={valuetext}
                                valueLabelDisplay="auto"
                                aria-labelledby="slider"
                                onChange={(event, newValue) => {
                                    setValue(newValue as number);
                                    props.onChange(newValue as number);
                                }}
                            />
                    </Grid>
                </Grid>
            );
        } else if (props.isRating) {
            return (<div>
                <Typography component="legend">{props.label}</Typography>
                <Rating
                    max={max}
                    precision={precision}
                    name={props.rdn}
                    value={value}
                    onChange={(event: any) => {
                        let v: number | undefined = undefined;
                        if (event.target.value) {
                            v = Number(event.target.value);
                            v = v * 100 / max;
                        }
                        setValue(v);
                        props.onChange(v);
                    }}
                />
            </div>)
        } else if (props.isSlider) {
            return (<div>
                <Typography component="legend" className={classes.marginBottom}>{props.label}</Typography>
                <Slider
                    value={value}
                    defaultValue={value}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="on"
                    step={constraint.step ?? 1}
                    marks={((constraint.max ?? 21) - (constraint.min ?? 0)) / (constraint.step ?? 1) < 20}
                    min={constraint.min}
                    max={constraint.max}
                    onChange={(event, newValue) => {
                        setValue(newValue as number);
                        props.onChange(newValue as number);
                    }}
                />
            </div>)
        } else {
            return (
                <TextField
                    id={props.rdn}
                    name={props.rdn}
                    label={props.label}
                    type="number"
                    margin="normal"
                    required
                    fullWidth
                    value={value?.toString() ?? ""}
                    error={minError || !value}
                    helperText={(minError || !value) ? getLabel(`error.min.${props.rdn}`) ?? getLabel('error.min.default') : ''}
                    onChange={(event) => {
                        setValue(Number(event.target.value));
                        props.onChange(Number(event.target.value))
//                        let v: number | undefined = undefined;
//                        if (event.target.value) {
//                            if (event.target.value.length > 0) {
//                                v = Number(event.target.value) ?? undefined;
//                            }

//                            if (constraint.max && v > constraint.max) {v = constraint.max}
//                            if (constraint.min && v < constraint.min) {v = constraint.min}
//                            setValue(v);
//                            props.onChange(v);
//                        } else {
//                            setValue(undefined);
//                            props.onChange(undefined);
//                        }
                    }}
                />
            );
        }
    } else {
        return <div/>;
    }
};

export default NumberComponent;
