import React from 'react';
import {match} from "react-router-dom";
import Parse from "parse";

interface ClasslinkProps {
    match?: match<{ token: string }>;
}

const LoginClasslink: React.FC<ClasslinkProps> = (_props) => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
        (async () => {
            const {token} = await Parse.Cloud.run('classlink.login', {
                code: code
            });
            window.location.href = `/become/${token}`;
        })();
    }

    return <div>Logging in...</div>;
};

export default LoginClasslink;
