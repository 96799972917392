import React, {useEffect} from 'react';
import {match} from "react-router-dom";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Box, Grid, Link} from "@material-ui/core";
import LineGraph from "./LineGraph";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {DataGrid, GridValueFormatterParams} from "@material-ui/data-grid";
import Loading from '../LoadingComponent';
import PieGraph from "./PieGraph";
import MapChart from "./Map";
import {loadReport} from "../../redux/reportSlice";
import DataExport from "./DataExport";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

const inputLabels = [
    {key: "2015", color: "#8884d8"},
    {key: "2016", color: "#82ca9d"},
    {key: "2017", color: "#81cc2d"},
    {key: "2018", color: "#d05742"},
    {key: "2019", color: "#73ef99"},
    {key: "2020", color: "#53bb51"},
    {key: "2021", color: "#c76e14"},
    {key: "2022", color: "#ac14c7"},
    {key: "2023", color: "#631857"},
    {key: "2024", color: "#3d8066"}
];

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) =>
    createStyles({
        padded: {
            padding: theme.spacing(3),
            textAlign: 'center'
        }
    })
);

interface Identifiable {
    id: string;
}

interface DashboardProps {
    match?: match<Identifiable>;
}

const Dashboard: React.FC<DashboardProps> = (props) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const [value, setValue] = React.useState(0);
    const dispatch = useAppDispatch();

    const report = useAppSelector((state) => state.report.report);
    const errorMessage = useAppSelector((state) => state.report.errorMessage);
    const isLoading = useAppSelector((state) => state.report.isLoading);
    const ringRdn = props.match?.params.id ?? 'us.oh';
    const showMap = (ringRdn === 'us.oh' || ringRdn === 'us.pa');
    const showExport = (ringRdn === 'co' || 'us.oh.tri-county-esc');

    useEffect(() => {
//        dispatch(loadReport( "report.solesCount", "us.oh", {from:new Date("2000-1-1"), to:new Date("2100-1-1"), interval:"day"}));
        dispatch(loadReport("report.getDashboardData", ringRdn, {}));
    }, []);

    const handleChange = (event: React.ChangeEvent<{}> | undefined, newValue: number) => {
        setValue(newValue);
    };

    const usersPerMonth = () => {
        const data = {
            dataKey: "name",
            oyLabel: getLabel('ui.admin.users_per_month_y_axis'),
            oxLabel: getLabel('ui.admin.users_per_month_x_axis'),
            yLimit: [0, 10],
            values:[{}]
        };

        if (report && report.data !== undefined) {
            data.values = [
                {
                    name: 'Jan',
                    2015: report.data.userCountByMonth['2015'][0] > 0 ? report.data.userCountByMonth['2015'][0] : null,
                    2016: report.data.userCountByMonth['2016'][0] > 0 ? report.data.userCountByMonth['2016'][0] : null,
                    2017: report.data.userCountByMonth['2017'][0] > 0 ? report.data.userCountByMonth['2017'][0] : null,
                    2018: report.data.userCountByMonth['2018'][0] > 0 ? report.data.userCountByMonth['2018'][0] : null,
                    2019: report.data.userCountByMonth['2019'][0] > 0 ? report.data.userCountByMonth['2019'][0] : null,
                    2020: report.data.userCountByMonth['2020'][0] > 0 ? report.data.userCountByMonth['2020'][0] : null,
                    2021: report.data.userCountByMonth['2021'][0] > 0 ? report.data.userCountByMonth['2021'][0] : null,
                    2022: report.data.userCountByMonth['2022'][0] > 0 ? report.data.userCountByMonth['2022'][0] : null,
                    2023: report.data.userCountByMonth['2023'][0] > 0 ? report.data.userCountByMonth['2023'][0] : null,
                    2024: report.data.userCountByMonth['2024'][0] > 0 ? report.data.userCountByMonth['2024'][0] : null
                },
                {
                    name: 'Feb',
                    2015: report.data.userCountByMonth['2015'][1] > 0 ? report.data.userCountByMonth['2015'][1] : null,
                    2016: report.data.userCountByMonth['2016'][1] > 0 ? report.data.userCountByMonth['2016'][1] : null,
                    2017: report.data.userCountByMonth['2017'][1] > 0 ? report.data.userCountByMonth['2017'][1] : null,
                    2018: report.data.userCountByMonth['2018'][1] > 0 ? report.data.userCountByMonth['2018'][1] : null,
                    2019: report.data.userCountByMonth['2019'][1] > 0 ? report.data.userCountByMonth['2019'][1] : null,
                    2020: report.data.userCountByMonth['2020'][1] > 0 ? report.data.userCountByMonth['2020'][1] : null,
                    2021: report.data.userCountByMonth['2021'][1] > 0 ? report.data.userCountByMonth['2021'][1] : null,
                    2022: report.data.userCountByMonth['2022'][1] > 0 ? report.data.userCountByMonth['2022'][1] : null,
                    2023: report.data.userCountByMonth['2023'][1] > 0 ? report.data.userCountByMonth['2023'][1] : null,
                    2024: report.data.userCountByMonth['2024'][1] > 0 ? report.data.userCountByMonth['2024'][1] : null
                },
                {
                    name: 'Mar',
                    2015: report.data.userCountByMonth['2015'][2] > 0 ? report.data.userCountByMonth['2015'][2] : null,
                    2016: report.data.userCountByMonth['2016'][2] > 0 ? report.data.userCountByMonth['2016'][2] : null,
                    2017: report.data.userCountByMonth['2017'][2] > 0 ? report.data.userCountByMonth['2017'][2] : null,
                    2018: report.data.userCountByMonth['2018'][2] > 0 ? report.data.userCountByMonth['2018'][2] : null,
                    2019: report.data.userCountByMonth['2019'][2] > 0 ? report.data.userCountByMonth['2019'][2] : null,
                    2020: report.data.userCountByMonth['2020'][2] > 0 ? report.data.userCountByMonth['2020'][2] : null,
                    2021: report.data.userCountByMonth['2021'][2] > 0 ? report.data.userCountByMonth['2021'][2] : null,
                    2022: report.data.userCountByMonth['2022'][2] > 0 ? report.data.userCountByMonth['2022'][2] : null,
                    2023: report.data.userCountByMonth['2023'][2] > 0 ? report.data.userCountByMonth['2023'][2] : null,
                    2024: report.data.userCountByMonth['2024'][2] > 0 ? report.data.userCountByMonth['2024'][2] : null
                },
                {
                    name: 'Apr',
                    2015: report.data.userCountByMonth['2015'][3] > 0 ? report.data.userCountByMonth['2015'][3] : null,
                    2016: report.data.userCountByMonth['2016'][3] > 0 ? report.data.userCountByMonth['2016'][3] : null,
                    2017: report.data.userCountByMonth['2017'][3] > 0 ? report.data.userCountByMonth['2017'][3] : null,
                    2018: report.data.userCountByMonth['2018'][3] > 0 ? report.data.userCountByMonth['2018'][3] : null,
                    2019: report.data.userCountByMonth['2019'][3] > 0 ? report.data.userCountByMonth['2019'][3] : null,
                    2020: report.data.userCountByMonth['2020'][3] > 0 ? report.data.userCountByMonth['2020'][3] : null,
                    2021: report.data.userCountByMonth['2021'][3] > 0 ? report.data.userCountByMonth['2021'][3] : null,
                    2022: report.data.userCountByMonth['2022'][3] > 0 ? report.data.userCountByMonth['2022'][3] : null,
                    2023: report.data.userCountByMonth['2023'][3] > 0 ? report.data.userCountByMonth['2023'][3] : null,
                    2024: report.data.userCountByMonth['2024'][3] > 0 ? report.data.userCountByMonth['2024'][3] : null
                },
                {
                    name: 'May',
                    2015: report.data.userCountByMonth['2015'][4] > 0 ? report.data.userCountByMonth['2015'][4] : null,
                    2016: report.data.userCountByMonth['2016'][4] > 0 ? report.data.userCountByMonth['2016'][4] : null,
                    2017: report.data.userCountByMonth['2017'][4] > 0 ? report.data.userCountByMonth['2017'][4] : null,
                    2018: report.data.userCountByMonth['2018'][4] > 0 ? report.data.userCountByMonth['2018'][4] : null,
                    2019: report.data.userCountByMonth['2019'][4] > 0 ? report.data.userCountByMonth['2019'][4] : null,
                    2020: report.data.userCountByMonth['2020'][4] > 0 ? report.data.userCountByMonth['2020'][4] : null,
                    2021: report.data.userCountByMonth['2021'][4] > 0 ? report.data.userCountByMonth['2021'][4] : null,
                    2022: report.data.userCountByMonth['2022'][4] > 0 ? report.data.userCountByMonth['2022'][4] : null,
                    2023: report.data.userCountByMonth['2023'][4] > 0 ? report.data.userCountByMonth['2023'][4] : null,
                    2024: report.data.userCountByMonth['2024'][4] > 0 ? report.data.userCountByMonth['2024'][4] : null
                },
                {
                    name: 'Jun',
                    2015: report.data.userCountByMonth['2015'][5] > 0 ? report.data.userCountByMonth['2015'][5] : null,
                    2016: report.data.userCountByMonth['2016'][5] > 0 ? report.data.userCountByMonth['2016'][5] : null,
                    2017: report.data.userCountByMonth['2017'][5] > 0 ? report.data.userCountByMonth['2017'][5] : null,
                    2018: report.data.userCountByMonth['2018'][5] > 0 ? report.data.userCountByMonth['2018'][5] : null,
                    2019: report.data.userCountByMonth['2019'][5] > 0 ? report.data.userCountByMonth['2019'][5] : null,
                    2020: report.data.userCountByMonth['2020'][5] > 0 ? report.data.userCountByMonth['2020'][5] : null,
                    2021: report.data.userCountByMonth['2021'][5] > 0 ? report.data.userCountByMonth['2021'][5] : null,
                    2022: report.data.userCountByMonth['2022'][5] > 0 ? report.data.userCountByMonth['2022'][5] : null,
                    2023: report.data.userCountByMonth['2023'][5] > 0 ? report.data.userCountByMonth['2023'][5] : null,
                    2024: report.data.userCountByMonth['2024'][5] > 0 ? report.data.userCountByMonth['2024'][5] : null
                },
                {
                    name: 'Jul',
                    2015: report.data.userCountByMonth['2015'][6] > 0 ? report.data.userCountByMonth['2015'][6] : null,
                    2016: report.data.userCountByMonth['2016'][6] > 0 ? report.data.userCountByMonth['2016'][6] : null,
                    2017: report.data.userCountByMonth['2017'][6] > 0 ? report.data.userCountByMonth['2017'][6] : null,
                    2018: report.data.userCountByMonth['2018'][6] > 0 ? report.data.userCountByMonth['2018'][6] : null,
                    2019: report.data.userCountByMonth['2019'][6] > 0 ? report.data.userCountByMonth['2019'][6] : null,
                    2020: report.data.userCountByMonth['2020'][6] > 0 ? report.data.userCountByMonth['2020'][6] : null,
                    2021: report.data.userCountByMonth['2021'][6] > 0 ? report.data.userCountByMonth['2021'][6] : null,
                    2022: report.data.userCountByMonth['2022'][6] > 0 ? report.data.userCountByMonth['2022'][6] : null,
                    2023: report.data.userCountByMonth['2023'][6] > 0 ? report.data.userCountByMonth['2023'][6] : null,
                    2024: report.data.userCountByMonth['2024'][6] > 0 ? report.data.userCountByMonth['2024'][6] : null
                },
                {
                    name: 'Aug',
                    2015: report.data.userCountByMonth['2015'][7] > 0 ? report.data.userCountByMonth['2015'][7] : null,
                    2016: report.data.userCountByMonth['2016'][7] > 0 ? report.data.userCountByMonth['2016'][7] : null,
                    2017: report.data.userCountByMonth['2017'][7] > 0 ? report.data.userCountByMonth['2017'][7] : null,
                    2018: report.data.userCountByMonth['2018'][7] > 0 ? report.data.userCountByMonth['2018'][7] : null,
                    2019: report.data.userCountByMonth['2019'][7] > 0 ? report.data.userCountByMonth['2019'][7] : null,
                    2020: report.data.userCountByMonth['2020'][7] > 0 ? report.data.userCountByMonth['2020'][7] : null,
                    2021: report.data.userCountByMonth['2021'][7] > 0 ? report.data.userCountByMonth['2021'][7] : null,
                    2022: report.data.userCountByMonth['2022'][7] > 0 ? report.data.userCountByMonth['2022'][7] : null,
                    2023: report.data.userCountByMonth['2023'][7] > 0 ? report.data.userCountByMonth['2023'][7] : null,
                    2024: report.data.userCountByMonth['2024'][7] > 0 ? report.data.userCountByMonth['2024'][7] : null
                },
                {
                    name: 'Sep',
                    2015: report.data.userCountByMonth['2015'][8] > 0 ? report.data.userCountByMonth['2015'][8] : null,
                    2016: report.data.userCountByMonth['2016'][8] > 0 ? report.data.userCountByMonth['2016'][8] : null,
                    2017: report.data.userCountByMonth['2017'][8] > 0 ? report.data.userCountByMonth['2017'][8] : null,
                    2018: report.data.userCountByMonth['2018'][8] > 0 ? report.data.userCountByMonth['2018'][8] : null,
                    2019: report.data.userCountByMonth['2019'][8] > 0 ? report.data.userCountByMonth['2019'][8] : null,
                    2020: report.data.userCountByMonth['2020'][8] > 0 ? report.data.userCountByMonth['2020'][8] : null,
                    2021: report.data.userCountByMonth['2021'][8] > 0 ? report.data.userCountByMonth['2021'][8] : null,
                    2022: report.data.userCountByMonth['2022'][8] > 0 ? report.data.userCountByMonth['2022'][8] : null,
                    2023: report.data.userCountByMonth['2023'][8] > 0 ? report.data.userCountByMonth['2023'][8] : null,
                    2024: report.data.userCountByMonth['2024'][8] > 0 ? report.data.userCountByMonth['2024'][8] : null
                },
                {
                    name: 'Oct',
                    2015: report.data.userCountByMonth['2015'][9] > 0 ? report.data.userCountByMonth['2015'][9] : null,
                    2016: report.data.userCountByMonth['2016'][9] > 0 ? report.data.userCountByMonth['2016'][9] : null,
                    2017: report.data.userCountByMonth['2017'][9] > 0 ? report.data.userCountByMonth['2017'][9] : null,
                    2018: report.data.userCountByMonth['2018'][9] > 0 ? report.data.userCountByMonth['2018'][9] : null,
                    2019: report.data.userCountByMonth['2019'][9] > 0 ? report.data.userCountByMonth['2019'][9] : null,
                    2020: report.data.userCountByMonth['2020'][9] > 0 ? report.data.userCountByMonth['2020'][9] : null,
                    2021: report.data.userCountByMonth['2021'][9] > 0 ? report.data.userCountByMonth['2021'][9] : null,
                    2022: report.data.userCountByMonth['2022'][9] > 0 ? report.data.userCountByMonth['2022'][9] : null,
                    2023: report.data.userCountByMonth['2023'][9] > 0 ? report.data.userCountByMonth['2023'][9] : null,
                    2024: report.data.userCountByMonth['2024'][9] > 0 ? report.data.userCountByMonth['2024'][9] : null
                },
                {
                    name: 'Nov',
                    2015: report.data.userCountByMonth['2015'][10] > 0 ? report.data.userCountByMonth['2015'][10] : null,
                    2016: report.data.userCountByMonth['2016'][10] > 0 ? report.data.userCountByMonth['2016'][10] : null,
                    2017: report.data.userCountByMonth['2017'][10] > 0 ? report.data.userCountByMonth['2017'][10] : null,
                    2018: report.data.userCountByMonth['2018'][10] > 0 ? report.data.userCountByMonth['2018'][10] : null,
                    2019: report.data.userCountByMonth['2019'][10] > 0 ? report.data.userCountByMonth['2019'][10] : null,
                    2020: report.data.userCountByMonth['2020'][10] > 0 ? report.data.userCountByMonth['2020'][10] : null,
                    2021: report.data.userCountByMonth['2021'][10] > 0 ? report.data.userCountByMonth['2021'][10] : null,
                    2022: report.data.userCountByMonth['2022'][10] > 0 ? report.data.userCountByMonth['2022'][10] : null,
                    2023: report.data.userCountByMonth['2023'][10] > 0 ? report.data.userCountByMonth['2023'][10] : null,
                    2024: report.data.userCountByMonth['2024'][10] > 0 ? report.data.userCountByMonth['2024'][10] : null
                },
                {
                    name: 'Dec',
                    2015: report.data.userCountByMonth['2015'][11] > 0 ? report.data.userCountByMonth['2015'][11] : null,
                    2016: report.data.userCountByMonth['2016'][11] > 0 ? report.data.userCountByMonth['2016'][11] : null,
                    2017: report.data.userCountByMonth['2017'][11] > 0 ? report.data.userCountByMonth['2017'][11] : null,
                    2018: report.data.userCountByMonth['2018'][11] > 0 ? report.data.userCountByMonth['2018'][11] : null,
                    2019: report.data.userCountByMonth['2019'][11] > 0 ? report.data.userCountByMonth['2019'][11] : null,
                    2020: report.data.userCountByMonth['2020'][11] > 0 ? report.data.userCountByMonth['2020'][11] : null,
                    2021: report.data.userCountByMonth['2021'][11] > 0 ? report.data.userCountByMonth['2021'][11] : null,
                    2022: report.data.userCountByMonth['2022'][11] > 0 ? report.data.userCountByMonth['2022'][11] : null,
                    2023: report.data.userCountByMonth['2023'][11] > 0 ? report.data.userCountByMonth['2023'][11] : null,
                    2024: report.data.userCountByMonth['2024'][11] > 0 ? report.data.userCountByMonth['2024'][11] : null
                }
            ];
        } else if (isLoading || errorMessage) {
            data.values = [];
        }

        return data;
    };

    const solesPerMonth = () => {
        const data = {
            dataKey: "name",
            oyLabel: "new SOLEs",
            oxLabel: "months",
            yLimit: [0, 10],
            values:[{}]
        };

        if (report && report.data !== undefined) {
            data.values = [
                {
                    name: 'January',
                    2015: report.data.soleCountByMonth['2015'][0] > 0 ? report.data.soleCountByMonth['2015'][0] : null,
                    2016: report.data.soleCountByMonth['2016'][0] > 0 ? report.data.soleCountByMonth['2016'][0] : null,
                    2017: report.data.soleCountByMonth['2017'][0] > 0 ? report.data.soleCountByMonth['2017'][0] : null,
                    2018: report.data.soleCountByMonth['2018'][0] > 0 ? report.data.soleCountByMonth['2018'][0] : null,
                    2019: report.data.soleCountByMonth['2019'][0] > 0 ? report.data.soleCountByMonth['2019'][0] : null,
                    2020: report.data.soleCountByMonth['2020'][0] > 0 ? report.data.soleCountByMonth['2020'][0] : null,
                    2021: report.data.soleCountByMonth['2021'][0] > 0 ? report.data.soleCountByMonth['2021'][0] : null,
                    2022: report.data.soleCountByMonth['2022'][0] > 0 ? report.data.soleCountByMonth['2022'][0] : null,
                    2023: report.data.soleCountByMonth['2023'][0] > 0 ? report.data.soleCountByMonth['2023'][0] : null,
                    2024: report.data.soleCountByMonth['2024'][0] > 0 ? report.data.soleCountByMonth['2024'][0] : null
                },
                {
                    name: 'February',
                    2015: report.data.soleCountByMonth['2015'][1] > 0 ? report.data.soleCountByMonth['2015'][1] : null,
                    2016: report.data.soleCountByMonth['2016'][1] > 0 ? report.data.soleCountByMonth['2016'][1] : null,
                    2017: report.data.soleCountByMonth['2017'][1] > 0 ? report.data.soleCountByMonth['2017'][1] : null,
                    2018: report.data.soleCountByMonth['2018'][1] > 0 ? report.data.soleCountByMonth['2018'][1] : null,
                    2019: report.data.soleCountByMonth['2019'][1] > 0 ? report.data.soleCountByMonth['2019'][1] : null,
                    2020: report.data.soleCountByMonth['2020'][1] > 0 ? report.data.soleCountByMonth['2020'][1] : null,
                    2021: report.data.soleCountByMonth['2021'][1] > 0 ? report.data.soleCountByMonth['2021'][1] : null,
                    2022: report.data.soleCountByMonth['2022'][1] > 0 ? report.data.soleCountByMonth['2022'][1] : null,
                    2023: report.data.soleCountByMonth['2023'][1] > 0 ? report.data.soleCountByMonth['2023'][1] : null,
                    2024: report.data.soleCountByMonth['2024'][1] > 0 ? report.data.soleCountByMonth['2024'][1] : null
                },
                {
                    name: 'March',
                    2015: report.data.soleCountByMonth['2015'][2] > 0 ? report.data.soleCountByMonth['2015'][2] : null,
                    2016: report.data.soleCountByMonth['2016'][2] > 0 ? report.data.soleCountByMonth['2016'][2] : null,
                    2017: report.data.soleCountByMonth['2017'][2] > 0 ? report.data.soleCountByMonth['2017'][2] : null,
                    2018: report.data.soleCountByMonth['2018'][2] > 0 ? report.data.soleCountByMonth['2018'][2] : null,
                    2019: report.data.soleCountByMonth['2019'][2] > 0 ? report.data.soleCountByMonth['2019'][2] : null,
                    2020: report.data.soleCountByMonth['2020'][2] > 0 ? report.data.soleCountByMonth['2020'][2] : null,
                    2021: report.data.soleCountByMonth['2021'][2] > 0 ? report.data.soleCountByMonth['2021'][2] : null,
                    2022: report.data.soleCountByMonth['2022'][2] > 0 ? report.data.soleCountByMonth['2022'][2] : null,
                    2023: report.data.soleCountByMonth['2023'][2] > 0 ? report.data.soleCountByMonth['2023'][2] : null,
                    2024: report.data.soleCountByMonth['2024'][2] > 0 ? report.data.soleCountByMonth['2024'][2] : null
                },
                {
                    name: 'April',
                    2015: report.data.soleCountByMonth['2015'][3] > 0 ? report.data.soleCountByMonth['2015'][3] : null,
                    2016: report.data.soleCountByMonth['2016'][3] > 0 ? report.data.soleCountByMonth['2016'][3] : null,
                    2017: report.data.soleCountByMonth['2017'][3] > 0 ? report.data.soleCountByMonth['2017'][3] : null,
                    2018: report.data.soleCountByMonth['2018'][3] > 0 ? report.data.soleCountByMonth['2018'][3] : null,
                    2019: report.data.soleCountByMonth['2019'][3] > 0 ? report.data.soleCountByMonth['2019'][3] : null,
                    2020: report.data.soleCountByMonth['2020'][3] > 0 ? report.data.soleCountByMonth['2020'][3] : null,
                    2021: report.data.soleCountByMonth['2021'][3] > 0 ? report.data.soleCountByMonth['2021'][3] : null,
                    2022: report.data.soleCountByMonth['2022'][3] > 0 ? report.data.soleCountByMonth['2022'][3] : null,
                    2023: report.data.soleCountByMonth['2023'][3] > 0 ? report.data.soleCountByMonth['2023'][3] : null,
                    2024: report.data.soleCountByMonth['2024'][3] > 0 ? report.data.soleCountByMonth['2024'][3] : null
                },
                {
                    name: 'May',
                    2015: report.data.soleCountByMonth['2015'][4] > 0 ? report.data.soleCountByMonth['2015'][4] : null,
                    2016: report.data.soleCountByMonth['2016'][4] > 0 ? report.data.soleCountByMonth['2016'][4] : null,
                    2017: report.data.soleCountByMonth['2017'][4] > 0 ? report.data.soleCountByMonth['2017'][4] : null,
                    2018: report.data.soleCountByMonth['2018'][4] > 0 ? report.data.soleCountByMonth['2018'][4] : null,
                    2019: report.data.soleCountByMonth['2019'][4] > 0 ? report.data.soleCountByMonth['2019'][4] : null,
                    2020: report.data.soleCountByMonth['2020'][4] > 0 ? report.data.soleCountByMonth['2020'][4] : null,
                    2021: report.data.soleCountByMonth['2021'][4] > 0 ? report.data.soleCountByMonth['2021'][4] : null,
                    2022: report.data.soleCountByMonth['2022'][4] > 0 ? report.data.soleCountByMonth['2022'][4] : null,
                    2023: report.data.soleCountByMonth['2023'][4] > 0 ? report.data.soleCountByMonth['2023'][4] : null,
                    2024: report.data.soleCountByMonth['2024'][4] > 0 ? report.data.soleCountByMonth['2024'][4] : null
                },
                {
                    name: 'June',
                    2015: report.data.soleCountByMonth['2015'][5] > 0 ? report.data.soleCountByMonth['2015'][5] : null,
                    2016: report.data.soleCountByMonth['2016'][5] > 0 ? report.data.soleCountByMonth['2016'][5] : null,
                    2017: report.data.soleCountByMonth['2017'][5] > 0 ? report.data.soleCountByMonth['2017'][5] : null,
                    2018: report.data.soleCountByMonth['2018'][5] > 0 ? report.data.soleCountByMonth['2018'][5] : null,
                    2019: report.data.soleCountByMonth['2019'][5] > 0 ? report.data.soleCountByMonth['2019'][5] : null,
                    2020: report.data.soleCountByMonth['2020'][5] > 0 ? report.data.soleCountByMonth['2020'][5] : null,
                    2021: report.data.soleCountByMonth['2021'][5] > 0 ? report.data.soleCountByMonth['2021'][5] : null,
                    2022: report.data.soleCountByMonth['2022'][5] > 0 ? report.data.soleCountByMonth['2022'][5] : null,
                    2023: report.data.soleCountByMonth['2023'][5] > 0 ? report.data.soleCountByMonth['2023'][5] : null,
                    2024: report.data.soleCountByMonth['2024'][5] > 0 ? report.data.soleCountByMonth['2024'][5] : null
                },
                {
                    name: 'July',
                    2015: report.data.soleCountByMonth['2015'][6] > 0 ? report.data.soleCountByMonth['2015'][6] : null,
                    2016: report.data.soleCountByMonth['2016'][6] > 0 ? report.data.soleCountByMonth['2016'][6] : null,
                    2017: report.data.soleCountByMonth['2017'][6] > 0 ? report.data.soleCountByMonth['2017'][6] : null,
                    2018: report.data.soleCountByMonth['2018'][6] > 0 ? report.data.soleCountByMonth['2018'][6] : null,
                    2019: report.data.soleCountByMonth['2019'][6] > 0 ? report.data.soleCountByMonth['2019'][6] : null,
                    2020: report.data.soleCountByMonth['2020'][6] > 0 ? report.data.soleCountByMonth['2020'][6] : null,
                    2021: report.data.soleCountByMonth['2021'][6] > 0 ? report.data.soleCountByMonth['2021'][6] : null,
                    2022: report.data.soleCountByMonth['2022'][6] > 0 ? report.data.soleCountByMonth['2022'][6] : null,
                    2023: report.data.soleCountByMonth['2023'][6] > 0 ? report.data.soleCountByMonth['2023'][6] : null,
                    2024: report.data.soleCountByMonth['2024'][6] > 0 ? report.data.soleCountByMonth['2024'][6] : null
                },
                {
                    name: 'August',
                    2015: report.data.soleCountByMonth['2015'][7] > 0 ? report.data.soleCountByMonth['2015'][7] : null,
                    2016: report.data.soleCountByMonth['2016'][7] > 0 ? report.data.soleCountByMonth['2016'][7] : null,
                    2017: report.data.soleCountByMonth['2017'][7] > 0 ? report.data.soleCountByMonth['2017'][7] : null,
                    2018: report.data.soleCountByMonth['2018'][7] > 0 ? report.data.soleCountByMonth['2018'][7] : null,
                    2019: report.data.soleCountByMonth['2019'][7] > 0 ? report.data.soleCountByMonth['2019'][7] : null,
                    2020: report.data.soleCountByMonth['2020'][7] > 0 ? report.data.soleCountByMonth['2020'][7] : null,
                    2021: report.data.soleCountByMonth['2021'][7] > 0 ? report.data.soleCountByMonth['2021'][7] : null,
                    2022: report.data.soleCountByMonth['2022'][7] > 0 ? report.data.soleCountByMonth['2022'][7] : null,
                    2023: report.data.soleCountByMonth['2023'][7] > 0 ? report.data.soleCountByMonth['2023'][7] : null,
                    2024: report.data.soleCountByMonth['2024'][7] > 0 ? report.data.soleCountByMonth['2024'][7] : null
                },
                {
                    name: 'September',
                    2015: report.data.soleCountByMonth['2015'][8] > 0 ? report.data.soleCountByMonth['2015'][8] : null,
                    2016: report.data.soleCountByMonth['2016'][8] > 0 ? report.data.soleCountByMonth['2016'][8] : null,
                    2017: report.data.soleCountByMonth['2017'][8] > 0 ? report.data.soleCountByMonth['2017'][8] : null,
                    2018: report.data.soleCountByMonth['2018'][8] > 0 ? report.data.soleCountByMonth['2018'][8] : null,
                    2019: report.data.soleCountByMonth['2019'][8] > 0 ? report.data.soleCountByMonth['2019'][8] : null,
                    2020: report.data.soleCountByMonth['2020'][8] > 0 ? report.data.soleCountByMonth['2020'][8] : null,
                    2021: report.data.soleCountByMonth['2021'][8] > 0 ? report.data.soleCountByMonth['2021'][8] : null,
                    2022: report.data.soleCountByMonth['2022'][8] > 0 ? report.data.soleCountByMonth['2022'][8] : null,
                    2023: report.data.soleCountByMonth['2023'][8] > 0 ? report.data.soleCountByMonth['2023'][8] : null,
                    2024: report.data.soleCountByMonth['2024'][8] > 0 ? report.data.soleCountByMonth['2024'][8] : null
                },
                {
                    name: 'October',
                    2015: report.data.soleCountByMonth['2015'][9] > 0 ? report.data.soleCountByMonth['2015'][9] : null,
                    2016: report.data.soleCountByMonth['2016'][9] > 0 ? report.data.soleCountByMonth['2016'][9] : null,
                    2017: report.data.soleCountByMonth['2017'][9] > 0 ? report.data.soleCountByMonth['2017'][9] : null,
                    2018: report.data.soleCountByMonth['2018'][9] > 0 ? report.data.soleCountByMonth['2018'][9] : null,
                    2019: report.data.soleCountByMonth['2019'][9] > 0 ? report.data.soleCountByMonth['2019'][9] : null,
                    2020: report.data.soleCountByMonth['2020'][9] > 0 ? report.data.soleCountByMonth['2020'][9] : null,
                    2021: report.data.soleCountByMonth['2021'][9] > 0 ? report.data.soleCountByMonth['2021'][9] : null,
                    2022: report.data.soleCountByMonth['2022'][9] > 0 ? report.data.soleCountByMonth['2022'][9] : null,
                    2023: report.data.soleCountByMonth['2023'][9] > 0 ? report.data.soleCountByMonth['2023'][9] : null,
                    2024: report.data.soleCountByMonth['2024'][9] > 0 ? report.data.soleCountByMonth['2024'][9] : null
                },
                {
                    name: 'November',
                    2015: report.data.soleCountByMonth['2015'][10] > 0 ? report.data.soleCountByMonth['2015'][10] : null,
                    2016: report.data.soleCountByMonth['2016'][10] > 0 ? report.data.soleCountByMonth['2016'][10] : null,
                    2017: report.data.soleCountByMonth['2017'][10] > 0 ? report.data.soleCountByMonth['2017'][10] : null,
                    2018: report.data.soleCountByMonth['2018'][10] > 0 ? report.data.soleCountByMonth['2018'][10] : null,
                    2019: report.data.soleCountByMonth['2019'][10] > 0 ? report.data.soleCountByMonth['2019'][10] : null,
                    2020: report.data.soleCountByMonth['2020'][10] > 0 ? report.data.soleCountByMonth['2020'][10] : null,
                    2021: report.data.soleCountByMonth['2021'][10] > 0 ? report.data.soleCountByMonth['2021'][10] : null,
                    2022: report.data.soleCountByMonth['2022'][10] > 0 ? report.data.soleCountByMonth['2022'][10] : null,
                    2023: report.data.soleCountByMonth['2023'][10] > 0 ? report.data.soleCountByMonth['2023'][10] : null,
                    2024: report.data.soleCountByMonth['2024'][10] > 0 ? report.data.soleCountByMonth['2024'][10] : null
                },
                {
                    name: 'December',
                    2015: report.data.soleCountByMonth['2015'][11] > 0 ? report.data.soleCountByMonth['2015'][11] : null,
                    2016: report.data.soleCountByMonth['2016'][11] > 0 ? report.data.soleCountByMonth['2016'][11] : null,
                    2017: report.data.soleCountByMonth['2017'][11] > 0 ? report.data.soleCountByMonth['2017'][11] : null,
                    2018: report.data.soleCountByMonth['2018'][11] > 0 ? report.data.soleCountByMonth['2018'][11] : null,
                    2019: report.data.soleCountByMonth['2019'][11] > 0 ? report.data.soleCountByMonth['2019'][11] : null,
                    2020: report.data.soleCountByMonth['2020'][11] > 0 ? report.data.soleCountByMonth['2020'][11] : null,
                    2021: report.data.soleCountByMonth['2021'][11] > 0 ? report.data.soleCountByMonth['2021'][11] : null,
                    2022: report.data.soleCountByMonth['2022'][11] > 0 ? report.data.soleCountByMonth['2022'][11] : null,
                    2023: report.data.soleCountByMonth['2023'][11] > 0 ? report.data.soleCountByMonth['2023'][11] : null,
                    2024: report.data.soleCountByMonth['2024'][11] > 0 ? report.data.soleCountByMonth['2024'][11] : null
                }
            ];
        } else if (isLoading || errorMessage) {
            data.values = [];
        }

        return data;
    };

    const usersBySubject = () => {
        const data = {
            values:[{}]
        };

        let otherTotal = 0;
        let allTotal = 0;

        if (report && report.data !== undefined) {
            const newValues = [];
            for (const subject in report.data.userCountBySubject) {
                // TODO: is there an easy way to get subjects in here, maybe with getLabel?
                switch (subject) {
                    case "top.careereducation" ||
                        "top.physicaleducation" ||
                        "top.engineering" ||
                        "top.englishlanguagedevelopment" ||
                        "top.financialliteracy" ||
                        "top.health" ||
                        "top.libmedia-infolit" ||
                        "top.lifeskills" ||
                        "top.technology" ||
                        "top.thearts" ||
                        "top.foreignlanguage":
                        otherTotal += report.data.userCountBySubject[subject];
                        break;
                    case "top.english":
                        newValues.push({
                            name: "English",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.math":
                        newValues.push({
                            name: "Math",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.science":
                        newValues.push({
                            name: "Science",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.socialstudies":
                        newValues.push({
                            name: "Social Studies",
                            value: report.data.userCountBySubject[subject]
                        });
                        break;
                    case "top.all" || "subjects.all":
                        allTotal += report.data.userCountBySubject[subject];
                        break;
                    default:
                    console.log('unknown subject');
                }
            }
            if (otherTotal > 0){
                newValues.push({
                    name: "Other",
                    value: otherTotal
                });
            }
            if (allTotal > 0){
                newValues.push({
                    name: "All Subjects",
                    value: allTotal
                });
            }
            data.values = newValues;
        }
        return data;
    };

    const usersByGrade = () => {
        const data = {
            values:[{}]
        };

        if (report && report.data !== undefined) {
            const newValues = [
                {
                    name: "Pre-K & Kindergarten",
                    value: report.data.userCountByGrade["edu.pre-k"] + report.data.userCountByGrade["edu.k"]
                },
                {
                    name: "Grades 1-3",
                    value: report.data.userCountByGrade["edu.1"] + report.data.userCountByGrade["edu.2"] + report.data.userCountByGrade["edu.3"]
                },
                {
                    name: "Grades 4-6",
                    value: report.data.userCountByGrade["edu.4"] + report.data.userCountByGrade["edu.5"] + report.data.userCountByGrade["edu.6"]
                },
                {
                    name: "Grades 7-8",
                    value: report.data.userCountByGrade["edu.7"] + report.data.userCountByGrade["edu.8"]
                },
                {
                    name: "Grades 9-10",
                    value: report.data.userCountByGrade["edu.9"] + report.data.userCountByGrade["edu.10"]
                },
                {
                    name: "Grades 11-12",
                    value: report.data.userCountByGrade["edu.11"] + report.data.userCountByGrade["edu.12"]
                }
            ];
            data.values = newValues;
        }
        return data;
    };

    const solesBySubject = () => {
        if (report && report.data !== undefined) {
            return {
                values:[
                    {
                        name: "English",
                        value: report.data.soleCountBySubjectBand[0]
                    },
                    {
                        name: "Math",
                        value: report.data.soleCountBySubjectBand[1]
                    },
                    {
                        name: "Science",
                        value: report.data.soleCountBySubjectBand[2]
                    },
                    {
                        name: "Social Studies",
                        value: report.data.soleCountBySubjectBand[3]
                    },
                    {
                        name: "Other",
                        value: report.data.soleCountBySubjectBand[4]
                    }
                ]
            };
        } else {
            return {
                values:[{}]
            };
        }
    };

    const solesByGrade = () => {
        if (report && report.data !== undefined) {
            return {
                values:[
                    {
                        name: "Pre-K & Kindergarten",
                        value: report.data.soleCountByGradeBand[0]
                    },
                    {
                        name: "Grades 1-6",
                        value: report.data.soleCountByGradeBand[1]
                    },
                    {
                        name: "Grades 7-8",
                        value: report.data.soleCountByGradeBand[2]
                    },
                    {
                        name: "Grades 9-12",
                        value: report.data.soleCountByGradeBand[3]
                    },
                    {
                        name: "Other",
                        value: report.data.soleCountByGradeBand[4]
                    }
                ]
            };
        } else {
            return {
                values:[{}]
            };
        }
    };

    const schoolRows = report?.data?.schools.map((school:any, index:number) => {return {id: index, ...school}});

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={getLabel('ui.admin.educators')} {...a11yProps(0)}/>
                <Tab label={getLabel('ui.admin.soles')} {...a11yProps(1)}/>
                <Tab label={getLabel('ui.admin.schools')} {...a11yProps(2)}/>
                {showMap ? <Tab label={getLabel('ui.admin.map')} {...a11yProps(3)}/> : null}
                {showExport ? <Tab label={getLabel('ui.admin.export')} {...a11yProps(showMap ? 4 : 3)}/> : null}
            </Tabs>
            <TabPanel value={value} index={0}>
                {report?.data ? <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper className={classes.padded}>
                            <LineGraph
                                title={getLabel('ui.admin.users_per_month_title')}
                                {...usersPerMonth()}
                                labels={inputLabels}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.users_by_subject')}
                                {...usersBySubject()}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.users_by_grade')}
                                {...usersByGrade()}
                            />
                        </Paper>
                    </Grid>
                </Grid> : <Loading rdn="ui.admin.loading_educators"/>}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {report?.data ? <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper className={classes.padded}>
                            <LineGraph
                                title="SOLEs per Month"
                                {...solesPerMonth()}
                                labels={inputLabels}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.soles_by_subject')}
                                {...solesBySubject()}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.padded}>
                            <PieGraph
                                title={getLabel('ui.admin.soles_by_grade')}
                                {...solesByGrade()}
                            />
                        </Paper>
                    </Grid>
                </Grid> : <Loading rdn="ui.admin.loading_soles"/>}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {schoolRows ? <div style={{height: 1400, width: '100%'}}><DataGrid
                    columns={
                        [
                            {
                                field: 'name',
                                headerName: 'Name',
                                flex:0.7,
                                renderCell: (params: GridValueFormatterParams) => (
                                    <Link href={`https://www.google.com/maps/search/?api=1&query=${params.value}&query_place_id=${params.row.placeId}`} target="_blank">
                                        {params.value}
                                    </Link>
                                )
                            },
                            {field: 'nUsers', headerName: 'User'},
                            {field: 'nSoles', headerName: 'Soles'}
                            // { field: 'placeId', headerName: 'PlaceID' }
                        ]
                    }
                    rows={ schoolRows }
                    pageSize={25}
                    autoHeight
                /></div> : <Loading rdn="ui.admin.loading_schools" fullHeight={true}/>}
            </TabPanel>
            {showMap ?
                <TabPanel value={value} index={3}>
                    <MapChart ring={ringRdn} onChange={handleChange}/>
                </TabPanel> :
                <></>
            }
            {showExport ?
                <TabPanel value={value} index={showMap ? 4 : 3}>
                    <DataExport ringRdn={ringRdn}/>
                </TabPanel> :
                <></>
            }
        </div>
    );
};

export default Dashboard;
