import React, {useState} from 'react';
import {AutoRotatingCarousel, Slide} from "material-auto-rotating-carousel";
import {teal} from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {getRingProps} from "../theme/RingProps";
import {useAppSelector} from "../redux/reduxHooks";
import {selectPub} from "../redux/userSlice";
import {useTranslationLabel} from "../hooks/useTranslation";

const AutoRotatingCarouselModal: React.FC = () => {

    const getLabel = useTranslationLabel();
    const [handleOpen, setHandleOpen] = useState({open: true});
    const pub = useAppSelector(selectPub);
    const mobile = useMediaQuery("(max-width:600px)");
    const customSlides = pub ? getRingProps(pub).customSlides ?? [] : [];
    const slides = [
        <Slide
            key="slide-0"
            media={
                <img src="/images/carousel/slide-1.png" alt=""/>
            }
            mediaBackgroundStyle={{backgroundColor: teal[50]}}
            style={{backgroundColor: teal[600]}}
            title={getLabel('ui.welcome_modal.title_0')}
            subtitle={getLabel('ui.welcome_modal.body_0')}
        />, <Slide
            key="slide-1"
            media={
                <img src="/images/carousel/slide-2.png" alt=""/>
            }
            mediaBackgroundStyle={{backgroundColor: teal[50]}}
            style={{backgroundColor: teal[600]}}
            title={getLabel('ui.welcome_modal.title_1')}
            subtitle={getLabel('ui.welcome_modal.body_1')}
            landscape
        />, <Slide
            key="slide-1"
            media={
                <img src="/images/carousel/slide-3.png" alt=""/>
            }
            mediaBackgroundStyle={{backgroundColor: teal[50]}}
            style={{backgroundColor: teal[600]}}
            title={getLabel('ui.welcome_modal.title_2')}
            subtitle={getLabel('ui.welcome_modal.body_2')}
        />].concat(customSlides);

    return (
        <AutoRotatingCarousel
            label="Get started"
            open={handleOpen.open}
            onClose={() => setHandleOpen({open: false})}
            onStart={() => setHandleOpen({open: false})}
            // autoplay={false}
            interval={7000}
            mobile={mobile}
            style={{position: "absolute"}}
        >
            {slides.map((slide, index) => {
                return slide;
            })}
        </AutoRotatingCarousel>
    );
};

export default AutoRotatingCarouselModal;

