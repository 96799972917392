import {IQuestion} from "@startsole/startsole-types/src/solejson";

export interface IQuestionResource {
   url:string;
   title:string;
}

export enum SoleState {
    StatePlanned = "planned",
    StateFacilitate = "facilitate",
    StateReflect = "reflect",
    StateCompleted = "completed",
    StateDeleted = "deleted"
}

export interface IObservation {
    id?: string;
    img?: string;
    thumb?: string;
    type?: string[];
    phase?: string;
    time?: number;
    notes?: string;
    feedback?: string;
    present?: boolean;
    group?: {
        number: number;
        creativity: number;
        presentedWithinTime: boolean;
        soundArgument?: number;
        withoutNotes?: boolean;
        didShareSources?: boolean;
        sourcesShared?: number;
        noNotes?: number;
    };
    engagementRatio?: number;
}

export interface ISole {
    /**
     * The SOLE ID
     * @TJS-type string
     * @title id
     */
    id?: string;
    state?: SoleState;
    question?: IQuestion;
    numStudents?: number;
    numDevices?: number;
    numGroups?: number;
    plannedDate?: Date;
    plannedDuration?: number;
    standards?: string[];
    grade?: string;
    subject?: string;
    materials?: string[];
    reflectionContentObjectiveAchievedRdn?: string;
    contentObjectiveRdn?: string;
    observations?: IObservation[];
    targetObservations?: IObservation[];
    reflectionTypeOfThinkingRdn?: string;
    reflectionCollaboration?: number;
    reflectionCollaborationNotes?: string;
    engagementRatio?: Number;
    reflectionTechnology?: number;
    reflectionTechnologyNotes?: string;
    reflectionCommunication?: number;
    reflectionCommunicationNotes?: string;
    reflectionGroundRules?: boolean;
    reflectionNeedHelp?: boolean;
    reflectionHelpText?: string;
    reflectionNotes?: string;
    tag?: string;
    documents?: {
        plan?: IDocument;
        summary?: IDocument;
    };
    templatePlan?: string;
    templateSummary?: string;
}

export interface ICertificate {
    id?: string;
    createdAt?: Date;
    file?: string;
}

export interface ISchool {
    id?: string;
    name?: string;
    placeId?: string;
    address?: string;
}

export interface IRing {
    id?: string;
    rdn?: string;
    name?: string;
    membershipCount?: number;
    soleCount?: number;
    schoolCount?: number;
}

// TODO: is there some reason we don't include email here?
export interface IUserPub {
    id?: string;
    updatedAt?: Date;
    role?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    grades?: string[];
    subjects?: string[];
    schoolId?: string;
    points?: number;
    roles?: string[];
    accountType?: string;
    rings?: IRing[];
    leaderRings?: IRing[];
    hasRing(ringRdn:string): boolean;
}

export class UserPub implements IUserPub {
    id?: string;
    updatedAt?: Date;
    role?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    grades?: string[];
    subjects?: string[];
    schoolId?: string;
    points?: number;
    roles?: string[];
    accountType?: string;
    rings?: IRing[];
    leaderRings?: IRing[];

    constructor(that?: IUserPub) {
        if (that !== undefined) {
            // this.id = that.id;
            this.updatedAt = that.updatedAt;
            this.role = that.role;
            this.email = that.email;
            this.firstName = that.firstName;
            this.lastName = that.lastName;
            this.grades = that.grades;
            this.subjects = that.subjects;
            this.schoolId = that.schoolId;
            this.points = that.points;
            this.roles = that.roles;
            this.accountType = that.accountType;
            this.rings = that.rings;
            this.leaderRings = that.leaderRings;
        }
    }

    get isComplete(): boolean {
//        return false;
        return (this.firstName?.length ?? 0) > 0 && (this.lastName?.length ?? 0) > 0;
    }

    hasRing(ringRdn:string): boolean {
        return this.rings?.find((ring) => ring.rdn === ringRdn) !== undefined;
    }
}

export interface IResource {
    file: string;
    image: string;
    title: string;
    short: string;
    description: string;
    authors: {
        email: string;
        name: string;
    }[];
    tags: string[];
}

export interface IResourceTag {
    key: string;
    text: string; // should be a i18n key
}

export interface IReport {
    name: string;
    ring: string;
    parms: {};
    data: IDashboardData | undefined;
}

interface IDocument {
    id: string;
    type: string;
    file: string;
    createdAt: Date;
}

export interface IDashboardData {
    soles: any;
    users: any;
    schools: any;
    name: any;
    rdn: any;
    soleCountByMonth: any;
    soleCountByGrade: any;
    soleCountBySubject: any;
    soleCountByGradeBand: any;
    soleCountBySubjectBand: any;
    userCountByMonth: any;
    userCountByGrade: any;
    userCountBySubject: any;
    userCountByGradeBand: any;
    userCountBySubjectBand: any;
}
