import React from "react";
import {Grid} from "@material-ui/core";

import SoleSelectionComponent from "../primitives/SoleSelectionComponent";
import {Props, ValidatePart} from "./00_PartProps";
import {isMobile} from "react-device-detect";

export const validateMaterialsPart:ValidatePart = (props) => {
    return null;
};

export const MaterialsPart: React.FC<Props> = ({props}) => {

    return (<>
        <Grid item {...props.gridSizes}>
            <SoleSelectionComponent
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="materials"
                rdn="session.materials"
                isExpanded={true}
            />
        </Grid>
    </>);
};

