import React, {useState} from "react";
import {
    Box,
    Dialog,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from "@material-ui/core";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {Props, ValidatePart} from "./00_PartProps";
import SoleSelectionComponent from "../primitives/SoleSelectionComponent";
import SoleStringComponent from "../primitives/SoleStringComponent";
import {HidableComponent} from "../../hoc/Hidable";
import SoleBooleanComponent from "../primitives/SoleBooleanComponent";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectPub} from "../../../redux/userSlice";

export const validateClassDetailsSubject:ValidatePart = ({sole, getLabel}) => {
    if (sole.subject === undefined) {
        return getLabel('ui.plan.missing_details_subject');
    } else if (sole.grade === undefined) {
        return getLabel('ui.plan.missing_details_grade');
    }
    return null;
};

export const ClassDetailsSubject: React.FC<Props> = ({props}) => {

    const getLabel = useTranslationLabel();
    const pub = useAppSelector(selectPub);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const hasRingCo = pub?.rings?.find((ring) => ring.rdn === "co") !== undefined;

    return (<>
        <Grid container justifyContent="center">
            <Grid item {...props.gridSizes}>
                <SoleSelectionComponent key={`subject-${props.sole.subject}`}
                                        sole={props.sole}
                                        setSole={props.setSole}
                                        jsonKey="subject"
                                        rdn="session.subject"
                />
            </Grid>
            <Grid item>
                <div style={{height:"1em", width:"1em"}}/>
            </Grid>
            <Grid item {...props.gridSizes}>
                <SoleSelectionComponent key={`grade-${props.sole.grade}`}
                                        sole={props.sole}
                                        setSole={props.setSole}
                                        jsonKey="grade"
                                        rdn="session.grade"
                />
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid item {...props.gridSizes}>
                <SoleStringComponent
                    label={getLabel('ui.plan.class_label')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="tag"
                    rdn="session.tag"
                />
            </Grid>
            <HidableComponent isVisible={hasRingCo}>
                <Grid item {...props.gridSizes}>
                    <Grid item>
                        <div style={{height:"2em", width:"1em"}}/>
                    </Grid>
                    <SoleBooleanComponent
                        label={getLabel('ui.plan.is_public')}
                        sole={props.sole}
                        setSole={props.setSole}
                        jsonKey="isPublic"
                        rdn="session.is_public"
                        radio={false}
                    />
                    <Box display="flex">
                        <Box m="auto">
                            <Typography variant="caption" align="center" onClick={handleOpenModal} style={{cursor:'pointer'}}>{getLabel('ui.learn_more')}</Typography>
                        </Box>
                    </Box>
                    <Dialog onClose={handleCloseModal} aria-labelledby="dialog-title" open={openModal}>
                        <DialogTitle id="dialog-title">{getLabel('ui.plan.is_public.modal_title')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {getLabel('ui.plan.is_public.modal_description')}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </HidableComponent>
        </Grid>
    </>)
};

