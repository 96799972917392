import React from "react";
import {Props, ValidatePart} from "./00_PartProps";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {createStyles, Grid} from "@material-ui/core";
import SoleBooleanComponent from "../primitives/SoleBooleanComponent";
import SoleStringComponent from "../primitives/SoleStringComponent";
import {makeStyles} from "@material-ui/core/styles";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectPub} from "../../../redux/userSlice";
import {HidableComponent} from "../../../@shared/Hocs/Hidable";

const useStyles = makeStyles((theme) =>
    createStyles({
        marginBottomOnly: {
            marginBottom: theme.spacing(5)
        }
    })
);

export const validateFinalPart:ValidatePart = ({sole, getLabel}) => {
    if (sole.reflectionGroundRules === undefined) {
        return getLabel('ui.reflect.missing_ground_rules');
    } else if (sole.reflectionNeedHelp === undefined) {
        return getLabel('ui.reflect.missing_needs_help');
    }
    return null;
};

const FinalPart: React.FC<Props> = ({props}) => {

    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const pub = useAppSelector(selectPub);
    const hasRingCo = pub?.rings?.find((ring) => ring.rdn === "co") !== undefined;

    return (<div>

        <HidableComponent isVisible={!hasRingCo}>
        <Grid item xs={12}>
            {/* TODO: change the rule for this DP to default to true*/}
            <SoleBooleanComponent
                label={getLabel("reflection.ground_rules")}
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="reflectionGroundRules"
                rdn="reflection.ground_rules"
                radio={true}
            />
        </Grid>
        </HidableComponent>

        <Grid item xs={12}>
            <div style={{height:"3em"}}/>
        </Grid>

        <Grid item xs={12} className={classes.marginBottomOnly}>
            <SoleBooleanComponent
                label={getLabel("reflection.need_help")}
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="reflectionNeedHelp"
                rdn="reflection.need_help"
                radio={true}
            />
            {props.sole.reflectionNeedHelp ?
                <SoleStringComponent
                    label={getLabel("reflection.help_text")}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionHelpText"
                    rdn="reflection.help_text"
                /> : <></>}
        </Grid>
    </div>)

};

export default FinalPart;

