import React, {useState} from "react";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography} from "@material-ui/core";
import {SoleStep} from "../common/SoleStepper";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {isMobile} from "react-device-detect";

import {QuestionPart, validateQuestionPart} from "../parts/10_QuestionPart";
import {StandardsPart, validateStandardsPart} from "../parts/11_StandardsPart";
import {ClassDetailsGroups, validateClassDetailsGroup} from "../parts/21_ClassDetailsGroups";
import {PlannedDatePart, validatePlannedDate} from "../parts/20_PlannedDatePart";
import {ClassDetailsSubject, validateClassDetailsSubject} from "../parts/22_ClassDetailsSubject";
import {MaterialsPart, validateMaterialsPart} from "../parts/30_MaterialsPart";
import {BehavioursPart, validateBehavioursPart} from "../parts/33_BehavioursPart";
import {TemplatePart, validateTemplatePart} from "../parts/34_TemplatePart";
import {ObjectivePart, validateObjectivePart} from "../parts/31_ObjectivePart";
import {ManagePart, validateManagePart} from "../parts/32_ManagePart";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectPub} from "../../../redux/userSlice";
import SoleBooleanComponent from "../primitives/SoleBooleanComponent";

const useSimpleDesktopPlanSteps = (): SoleStep[] => {
    const getLabel = useTranslationLabel();
    const [openModal, setOpenModal] = useState(false);

    return [
        {
            label: (_sole) => getLabel('ui.plan.big_question_and_standard'),
            content: (props) => (<>
                <Grid container justify="space-around">
                    <QuestionPart props={{...props, gridSizes: {xs: 12, sm: 10, md: 8, lg: 8}}}/>
                    <ObjectivePart props={{...props, gridSizes: {xs: 12, sm: 10, md: 8, lg: 8}}}/>
                </Grid>
                <Grid container justify="center">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <PlannedDatePart props={{...props, gridSizes: {xs: 12, sm: 5, md: 4, lg: 4}}} native={true}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <ClassDetailsGroups props={{...props, gridSizes: {xs: 12, sm: 4, md: 4, lg: 4}}} native={true}/>
                    <Grid item xs={12} sm={4}>
                        <Grid item>
                            <div style={{height:"2em", width:"1em"}}/>
                        </Grid>
                        <SoleBooleanComponent
                            label={getLabel('ui.plan.is_public')}
                            sole={props.sole}
                            setSole={props.setSole}
                            jsonKey="isPublic"
                            rdn="session.is_public"
                            radio={false}
                        />
                        <Box display="flex">
                            <Box m="auto">
                                <Typography variant="caption" align="center" onClick={() => setOpenModal(true)} style={{cursor:'pointer'}}>{getLabel('ui.learn_more')}</Typography>
                            </Box>
                        </Box>
                        <Dialog onClose={() => setOpenModal(false)} aria-labelledby="dialog-title" open={openModal}>
                            <DialogTitle id="dialog-title">{getLabel('ui.plan.is_public.modal_title')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {getLabel('ui.plan.is_public.modal_description')}
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                    <MaterialsPart props={{...props, gridSizes: {xs: 12, sm: 12, md: 8, lg: 8}}}/>
                    <BehavioursPart props={props}/>
                </Grid>
            </>),
            validate: (props) => {
                return validateQuestionPart(props) ||
                    validatePlannedDate(props) ||
                    validateClassDetailsGroup(props) ||
                    validateObjectivePart(props) ||
                    validateMaterialsPart(props) ||
                    validateBehavioursPart(props);
            }
        }
    ];
};

const useDesktopPlanSteps = (): SoleStep[] => {
    const getLabel = useTranslationLabel();

    return [
        {
            label: (_sole) => getLabel('ui.plan.big_question_and_standard'),
            content: (props) => (<Grid container justify="space-around">
                <QuestionPart props={{...props, gridSizes: {xs: 12, sm: 10, md: 8, lg: 8}}}/>
                <StandardsPart props={props}/>
            </Grid>),
            validate: (props) => {
                return validateQuestionPart(props) ||
                    validateStandardsPart(props);
            }
        },
        {
            label: (_sole) => getLabel('ui.plan.class_details'),
            content: (props) => (<form>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around" spacing={3}>
                        <PlannedDatePart props={props}/>
                        <ClassDetailsGroups props={props}/>
                    </Grid>
                    <br/><br/>
                    <ClassDetailsSubject props={props}/>
                </MuiPickersUtilsProvider>
            </form>),
            validate: (props) => {
                return validatePlannedDate(props) ||
                    validateClassDetailsGroup(props) ||
                    validateClassDetailsSubject(props);
            }
        },
        {
            label: (_sole) => getLabel('ui.plan.objective_logistics'),
            content: (props) => (<>
                <Grid container justify="space-around" spacing={3}>
                    <ObjectivePart props={{...props, gridSizes: {xs: 12, sm: 10, md: 8, lg: 8}}}/>
                </Grid>
                <br/>
                <Grid container justify="space-around" spacing={3}>
                    <MaterialsPart props={props}/>
                    <ManagePart props={props}/>
                    <BehavioursPart props={props}/>
                </Grid>
                <br/>
                <TemplatePart props={props}/>
            </>),
            validate: (props) => {
                return validateObjectivePart(props) ||
                    validateMaterialsPart(props) ||
                    validateManagePart(props) ||
                    validateBehavioursPart(props) ||
                    validateTemplatePart(props);
            }
        }
    ];
};

const useMobilePlanSteps = (): SoleStep[] => {
    const getLabel = useTranslationLabel();

    return [
        {
            label: (_sole) => getLabel('ui.plan.big_question_and_standard'),
            content: (props) => (<>
                <Grid container justify="space-around" spacing={0}>
                    <QuestionPart props={props}/>
                    <Grid item xs={12}>
                        <div style={{height: "1em"}}/>
                    </Grid>
                    <StandardsPart props={props}/>
                </Grid>
            </>),
            validate: (props) => {
                return validateQuestionPart(props) ||
                    validateStandardsPart(props);
            }
        },
        {
            label: (_sole) => getLabel('ui.question.plan_a_lesson'),
            content: (props) => (<form>
                <Grid container justify="space-around" spacing={0}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <PlannedDatePart props={props}/>
                    </MuiPickersUtilsProvider>
                </Grid>
            </form>),
            validate: (props) => validatePlannedDate(props)
        },
        {
            label: (_sole) => getLabel('ui.plan.class_details'),
            content: (props) => (<form>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ClassDetailsGroups props={props}/>
                </MuiPickersUtilsProvider>
            </form>),
            validate: (props) => validateClassDetailsGroup(props)
        },
        {
            label: (_sole) => getLabel('ui.plan.subject'),
            content: (props) => (<form>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ClassDetailsSubject props={props}/>
                </MuiPickersUtilsProvider>
                <TemplatePart props={props}/>
            </form>),
            validate: (props) => validateClassDetailsSubject(props)
        },
        {
            label: (_sole) => getLabel('ui.plan.materials'),
            content: (props) => (<>
                <MaterialsPart props={props}/>
            </>),
            validate: (props) => validateManagePart(props)
        }, {
            label: (_sole) => getLabel('ui.plan.objective'),
            content: (props) => (<>
                <ObjectivePart props={props}/>
                <br/>
                <ManagePart props={props}/>
            </>),
            validate: (props) => {
                return validateObjectivePart(props) ||
                    validateManagePart(props)
            }
        },
        {
            label: (_sole) => getLabel('ui.plan.observations'),
            content: (props) => (<>
                <BehavioursPart props={props}/>
            </>),
            validate: (props) => validateBehavioursPart(props)
        }
    ];
};

const usePlanSteps = () => {
    const mobileSteps = useMobilePlanSteps();
    const desktopSteps = useDesktopPlanSteps();
    const simpleDesktopSteps = useSimpleDesktopPlanSteps();
    const pub = useAppSelector(selectPub);
    const hasRingCo = pub?.rings?.find((ring) => ring.rdn === "co") !== undefined;

    if (hasRingCo) {
        return simpleDesktopSteps;
    } else {
        return isMobile ? mobileSteps : desktopSteps;
    }
};

export default usePlanSteps