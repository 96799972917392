import React, {useEffect} from "react";
import {KeyboardDatePicker, KeyboardTimePicker} from "@material-ui/pickers";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import SoleNumberComponent from "../primitives/SoleNumberComponent";
import {ValidatePart, Props, PartProps} from "./00_PartProps";
import {isMobile} from "react-device-detect";
import {Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import {HidableComponent} from "../../../@shared/Hocs/Hidable";
import moment from "moment";

export const validatePlannedDate: ValidatePart = (_props) => {
    return null;
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        width: '100%',
        margin: 'auto'
    }
}));

interface PlannedDatePartProps extends Props {
    native?: boolean;
}

export const PlannedDatePart: React.FC<PlannedDatePartProps> = ({props, native}) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    const handleDateTimeChange = (date: Date | null) => {
        if (date) {
            props.setSole({...props.sole, plannedDate: date});
        } else {
            props.setSole({...props.sole, plannedDate: new Date()})
        }
    };

    const xs = isMobile ? 12 : 5;

    return (
        <>
            {/* <HidableComponent isVisible={native ?? false}>*/}
            {/*    <Grid item {...props.gridSizes}>*/}
            {/*        <TextField*/}
            {/*            margin="normal"*/}
            {/*            id="datetime-picker-native"*/}
            {/*            label={getLabel('ui.plan.what_is_the_planned_date?')}*/}
            {/*            type="datetime-local"*/}
            {/*            value={moment(props.sole.plannedDate ?? new Date()).format('YYYY-MM-DDTHH:MM')}*/}
            {/*            defaultValue={moment(props.sole.plannedDate ?? new Date()).format('YYYY-MM-DDTHH:MM')}*/}
            {/*            onChange={(e) => handleDateTimeChange(moment(e.target.value ?? new Date(), 'YYYY-MM-DDTHH:MM').toDate())}*/}
            {/*            style={{minWidth:'200px'}}*/}
            {/*            className={classes.textField}*/}
            {/*        />*/}
            {/*    </Grid>*/}
            {/* </HidableComponent>*/}
            <HidableComponent isVisible={true}>
                <Grid item {...props.gridSizes}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={getLabel('ui.plan.what_is_the_planned_date?')}
                        format="MM/dd/yyyy"
                        fullWidth
                        value={props.sole.plannedDate}
                        onChange={handleDateTimeChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                    />
                </Grid>
                <Grid item {...props.gridSizes}>
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label={<Typography style={{minWidth:'200px'}}>{getLabel('ui.plan.what_is_the_planned_time?')}</Typography>}
                        fullWidth
                        value={props.sole.plannedDate}
                        onChange={handleDateTimeChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change time'
                        }}
                    />
                </Grid>
            </HidableComponent>

            <Grid item {...props.gridSizes}>
                <SoleNumberComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="plannedDuration"
                    rdn="session.planned_duration"
                    isSlider={native ? false : true}
                />
            </Grid>
        </>
    )
};
