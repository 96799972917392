import React, {useState} from "react";
import {Button, Typography} from "@material-ui/core";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import {useTranslationLabel} from "../../hooks/useTranslation";
import DateFnsUtils from "@date-io/date-fns";
import fileDownload from 'js-file-download';
import Parse from "parse";
import Loading from "../LoadingComponent";

const csvFileName = "ExportedSoles.csv";

type DownloadResult = {
    csv:string;
    count:number;
} | null;

interface Props {
    ringRdn: string;
}

const DataExport: React.FC<Props> = (props) => {

    const getLabel = useTranslationLabel();

    const from = new Date();
    from.setMonth(from.getMonth() - 1);
    const [fromDate, setFromDate] = useState<Date | null>(from);
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<DownloadResult>();

    const handleExport = async () => {
        setIsLoading(true);
            console.log(`Export ${fromDate?.toDateString()} to ${toDate?.toDateString()}`);
            try {
                const result = await Parse.Cloud.run('report.exportRing', {
                        ring: props.ringRdn,
                        options: {
                            type: "soles",
                            from: fromDate,
                            to: toDate
                        }
                    },
                    {
                        sessionToken: Parse.User.current()?.getSessionToken()
                    });
                setResult(result);
                if (result.count > 0) {
                    fileDownload(result.csv, csvFileName);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
    };

    if (isLoading) {
        return <Loading rdn="ui.admin.export_loading"/>
    }
    return (<form>
        <Typography variant="h6" component="h4">{getLabel('ui.admin.export_title')}</Typography>
        <div style={{maxWidth: "320px"}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={getLabel('ui.export_from_date')}
                    format="MM/dd/yyyy"
                    fullWidth
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date'
                    }}
                />
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={getLabel('ui.export_to_date')}
                    format="MM/dd/yyyy"
                    fullWidth
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date'
                    }}
                />
            </MuiPickersUtilsProvider>
            <div style={{height:"2em"}}/>
            <Button onClick={handleExport}
                variant="contained"
                size="large"
                color="secondary"
                autoFocus>
                {getLabel('ui.export_button')}
            </Button>
        </div>
        {result ? <Typography variant="caption" component="h3">
            <div style={{height:"1em"}}/>
            {getLabel('ui.admin.export_completed')?.replace("$COUNT", result.count.toString()).replace("$FILE", csvFileName)}
        </Typography> : ""}
    </form>);
};

export default DataExport;
